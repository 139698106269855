import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/mapa-strony/" className="sel">
              {" "}
              &gt; Mapa strony
            </a>
          </div>
          <div id="page_content">
            <h1>Mapa strony</h1>
            <ul id="sitemap">
              <li>
                <a className="big" href="/home/">
                  Strona główna
                </a>
                <a className="small" href="/polityka-prywatnosci/">
                  Polityka prywatności
                </a>
                <a className="small" href="/polityka-cookies/">
                  Polityka cookies
                </a>
                <a className="small" href="/nota-prawna/">
                  Nota prawna
                </a>
                <a className="small" href="/mapa-strony/">
                  Mapa strony
                </a>
                <a className="small" href="/slowniczek/">
                  Słowniczek
                </a>
                <a className="small" href="/reklama-telewizyjna/">
                  Reklama telewizyjna
                </a>
                <a className="big" href="/katar/">
                  Katar
                </a>
                <a className="big" href="/katar-u-dzieci/">
                  Katar u dzieci
                </a>
                <a className="big" href="/katar-i-bol-glowy/">
                  Katar i uczucie ucisku w głowie
                </a>
                <a className="small" href="/polityka-cookies-aneks/">
                  Aneks Polityki cookies
                </a>
              </li>
              <li>
                <a className="big" href="/produkty/">
                  Produkty
                </a>
                <a className="small" href="/produkty/sudafed_xylospray_dex/">
                  SUDAFED® XYLOSPRAY DEX
                </a>
                <a
                  className="small"
                  href="/produkty/sudafed_xylospray_dex_kids/"
                >
                  SUDAFED® XYLOSPRAY DEX DLA DZIECI
                </a>
                <a className="small" href="/produkty/sudafed_xylospray_ha/">
                  SUDAFED® XYLOSPRAY HA
                </a>
                <a
                  className="small"
                  href="/produkty/sudafed_xylospray_ha_dla_dzieci/"
                >
                  SUDAFED® XYLOSPRAY HA DLA DZIECI
                </a>
                <a className="small" href="/produkty/sudafed_xylospray/">
                  SUDAFED® XYLOSPRAY
                </a>
                <a
                  className="small"
                  href="/produkty/sudafed_xylospray_dla_dzieci/"
                >
                  SUDAFED® XYLOSPRAY DLA DZIECI
                </a>
                <a
                  className="small"
                  href="/produkty/actifed_syrop_na_katar_i_kaszel/"
                >
                  ACTIFED® SYROP NA KATAR I KASZEL
                </a>
                <a className="small" href="/produkty/sudafed_tabletki/">
                  SUDAFED® TABLETKI
                </a>
              </li>
              <li>
                <a className="big" href="/poradnik/">
                  Poradnik
                </a>
                <a href="/poradnik/" className="meddium">
                  Katar
                </a>
                <a
                  href="/poradnik/katar/jak_prawidlowo_oczyszczac_nos_podczas_kataru/"
                  className="tab"
                >
                  Jak prawidłowo oczyszczać nos podczas kataru?
                </a>
                <a
                  href="/poradnik/katar/poznaj_rodzaje_kataru/"
                  className="tab"
                >
                  Poznaj rodzaje kataru
                </a>
                <a
                  href="/poradnik/katar/podejmij_szybka_walke_z_katarem/"
                  className="tab"
                >
                  Podejmij szybką walkę z katarem
                </a>
                <a
                  href="/poradnik/katar/katar_alergiczny_-_poznaj_i_zapobiegaj/"
                  className="tab"
                >
                  Katar alergiczny - poznaj i zapobiegaj
                </a>
                <a href="/poradnik/katar/czym_jest_katar/" className="tab">
                  Czym jest katar?
                </a>
                <a
                  href="/poradnik/katar/co_to_jest_zapalenie_zatok/"
                  className="tab"
                >
                  Co to jest zapalenie zatok?
                </a>
                <a
                  href="/poradnik/katar/skutki_niewyleczonego_kataru/"
                  className="tab"
                >
                  Skutki niewyleczonego kataru
                </a>
                <a
                  href="/poradnik/katar/pokonaj_zapalenie_zatok/"
                  className="tab"
                >
                  Pokonaj zapalenie zatok
                </a>
                <a href="/poradnik/" className="meddium">
                  Przeziębienie
                </a>
                <a
                  href="/poradnik/przeziebienie/domowe_sposoby_na_walke_z_przeziebieniem/"
                  className="tab"
                >
                  Domowe sposoby na walkę z przeziębieniem
                </a>
                <a
                  href="/poradnik/przeziebienie/cztery_fazy_rozwoju_przeziebienia/"
                  className="tab"
                >
                  Cztery fazy rozwoju przeziębienia
                </a>
                <a
                  href="/poradnik/przeziebienie/jak_uchronic_sie_przed_przeziebieniem/"
                  className="tab"
                >
                  Jak uchronić się przed przeziębieniem?
                </a>
                <a href="/poradnik/" className="meddium">
                  Dzieci
                </a>
                <a
                  href="/poradnik/dzieci/zatkany_nos_u_dziecka/"
                  className="tab"
                >
                  Zatkany nos u dziecka
                </a>
                <a
                  href="/poradnik/dzieci/odpornosc_dziecka_-_zadbaj_i_zwiekszaj/"
                  className="tab"
                >
                  Odporność dziecka - zadbaj i zwiększaj
                </a>
                <a
                  href="/poradnik/dzieci/walka_z_infekcjami_-_katar_i_kaszel_u_dziecka_/"
                  className="tab"
                >
                  Walka z infekcjami - katar i kaszel u dziecka{" "}
                </a>
              </li>
              <li>
                <a className="big" href="/dobierz-lek/">
                  Test
                </a>
                <a className="big" href="/kontakt/">
                  Kontakt
                </a>
                <a className="big" href="/pytania-i-odpowiedzi/">
                  Pytania i odpowiedzi
                </a>
              </li>
            </ul>
          </div>
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px"
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a id="ot-sdk-btn" className="ot-sdk-show-settings" rel="nofollow"> Ustawienia plików Cookie </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty. 2023
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
